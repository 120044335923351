:root {
	--background-color: #333;
	--color: #FFF;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	background: var(--background-color) url('./bg.jpg') no-repeat center center/cover; 
	background-size: cover;
	background-attachment: fixed; 
}

#video-background {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
}


h1 {
	position: absolute;
	bottom: 1%;
	left: 5%;
	width: 94%;
	font-size: 36px;
	letter-spacing: 3px;
	color: var(--color);
	font-family: Montserrat, Arial, Helvetica, sans-serif;
	text-align: left;
}